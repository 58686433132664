import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import SliderBlock from "../SliderBlock";
import { ImageInterface, Slug } from "../../types/SanityTypes";
import "swiper/scss";
import "./styles.scss";
import { Container } from "react-bootstrap";
import { createThemeClassName } from "../../utils/themeClass";

import { graphql, useStaticQuery } from "gatsby";
import { LocalizedContext } from "../../services/LocalizedContextService";

export interface ArticleSliderInterface {
  heading?: string;
  bgColor?: {
    title: string;
  };
  secondaryHeading?: string;
  secHeadingColor?: {
    title: string;
  };
  content: {
    _id: string;
    _rawHeroImage: ImageInterface;
    slug: {
      current: string;
    };
    headline: string;
    parentSection: Slug & {
      parentSection?: Slug;
    };
    // For Preview
    heroImage?: ImageInterface;
  }[];
  themeColor?: string;
  theme: {
    name: string;
  };
  sectionPage: {
    slug: {
      current: string;
    };
  };
}

const ArticleByCategorySlider: FunctionComponent<ArticleSliderInterface> = ({
  heading,
  bgColor,
  content,
  themeColor,
  secHeadingColor,
  secondaryHeading,
  sectionPage,
  theme
}) => {
  const { market } = useContext(LocalizedContext);
  
  const titleColor = themeColor?.toLowerCase() || bgColor?.title.toLowerCase();
  const secondaryHeadingColor = secHeadingColor?.title?.toLowerCase() || bgColor?.title.toLowerCase();

  const outColor = theme?.name === "darkteal-piano" ? "#9c9c9c" : "blue";

  const colorList: { [key: string]: string } = {
    purple: "var(--purple-light-color)",
    orange: "var(--orange-bright-color)",
    pink: "var(--pink-bright-color)",
    teal: "var(--teal-color)",
    primary: "var(--primary-color)",
    red: "var(--orange-red-color)",
    blue: "var(--blue-dark-color)",
    "light blue": "var(--blue-bright-color)",
    white: "var(--white-color)"
  };

    const getAllArticles = useStaticQuery(graphql`
        query allArticles {
            allSanityArticle {
                nodes {
                    market
                    _id
                    parentSection {
                        ... on SanitySectionPage {
                            slug {
                                current
                            }
                        }
                    }
                    heroImage {
                        asset {
                          url
                        }
                        alt
                    }
                    slug {
                        _key
                        _type
                        current
                    }
                    headline
                }
            }
        }
    `).allSanityArticle.nodes;

    const filterArticles = () => {
        const filteredList = getAllArticles.filter((article) => {
            return article.parentSection.slug?.current === sectionPage.slug.current && article.market === market;
        });

        return filteredList;
    }

    let filteredArticles = filterArticles();

  return (
    <section
      className={`article-slider ${createThemeClassName(theme?.name)} ${secondaryHeading ? "article-slider-home" : ""}`}
      data-testid="article-slider"
    >
      <Container fluid>
        {heading && (
          <h2 style={{ color: colorList[titleColor as string] }} data-testid="article-slider-heading">
            {heading}{" "}
            {secondaryHeading && (
              <span style={{ color: colorList[secondaryHeadingColor as string] }}>
                <br />
                {secondaryHeading}
              </span>
            )}
          </h2>
        )}
        {filteredArticles && (
          <SliderBlock
            data={filteredArticles}
            productColor={outColor}
            textColor={colorList[titleColor as string]}
            type="article"
          />
        )}
      </Container>
    </section>
  );
};

export default ArticleByCategorySlider;
